
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-tabs
      active-nav-item-class="nav nav-tabs"
      content-class="mt-3"
    >
      <b-tab title="Individual Customers" active>
        <div class="row">
          <div class="col-md-3">
            <br/>
            <div class="input-group mb-3 pull-right">

              <input type="text" v-model="phoneNumber" class="form-control" placeholder="Search by phone number or name" aria-label="" aria-describedby="basic-addon1">

              <div class="input-group-append">
                <button @click="searchCustomer" class="btn btn-primary" type="button">Search</button>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <a href="/app/importCustomers" class="btn btn-danger float-right ml-5" >Import customers</a>
            <b-button class="btn btn-danger float-right" v-b-modal.add-customer>Add customer</b-button>
          </div>
        </div>

        <br/>
        <b-table striped hover :items="customers" :fields="columns">
          <template v-slot:cell(loyaltyId)="data">
            <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/customer/'+data.item.id">{{ data.value }}</a>
          </template>
          <template v-slot:cell(status)="data">
            <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">
                   {{ data.value }}
                </span>
                <span class="badge badge-danger"  v-else >
                  {{ data.value }}
            </span>
          </template>
          <template v-slot:cell(customerType)="data">
            <span  class="badge badge-success"  v-if="data.value == 'ACCOUNT_OWNER'">
                   ACCOUNT OWNER
                </span>
            <span class="badge badge-warning"  v-else >
                  DEPENDENT
            </span>
          </template>
          <template v-slot:cell(options)="data">

            <b-dropdown text="options">
              <b-dropdown-item href="#"  @click="openEditCustomerModal(data.item)">Edit</b-dropdown-item>
              <b-dropdown-item href="#" v-if="data.item.status == 'ACTIVE'" @click="openUpdateCustomerStatus('SUSPENDED', data.item)"  >Deactivate</b-dropdown-item>
              <b-dropdown-item href="#" v-else @click="openUpdateCustomerStatus('ACTIVE', data.item)"  >Activate</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumber"
                    v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>

      </b-tab>
      <b-tab title="Institutions">
        <div class="row">
          <div class="col-md-3">
            <br/>
            <div class="input-group mb-3 pull-right">

              <input type="text" v-model="phoneNumber" class="form-control" placeholder="Search by phone number or name" aria-label="" aria-describedby="basic-addon1">

              <div class="input-group-append">
                <button @click="searchCustomer" class="btn btn-primary" type="button">Search</button>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <b-button class="btn btn-success float-right" v-b-modal.add-institution>Add institution</b-button>
          </div>
        </div>

        <br/>
        <b-table striped hover :items="institutions" :fields="institution_columns">
          <template v-slot:cell(loyaltyId)="data">
            <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/customer/'+data.item.id">{{ data.value }}</a>
          </template>
          <template v-slot:cell(status)="data">
            <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">
                   {{ data.value }}
                </span>
            <span class="badge badge-danger"  v-else >
                  {{ data.value }}
            </span>
          </template>
          <template v-slot:cell(certificateOfRegistration)="data">
            <a v-if="data.value" :href="data.value" target="_blank">CR12</a>
          </template>
          <template v-slot:cell(taxCompliance)="data">
            <a v-if="data.value" :href="data.value" target="_blank">KRA Pin</a>
          </template>
          <template v-slot:cell(directorsId)="data">
            <a v-if="data.value" :href="data.value" target="_blank">Director's ID</a>
          </template>
          <template v-slot:cell(boardResolution)="data">
            <a v-if="data.value" :href="data.value" target="_blank">Board Resolution</a>
          </template>
          <template v-slot:cell(customerType)="data">
            <span  class="badge badge-success"  v-if="data.value == 'ACCOUNT_OWNER'">
                   ACCOUNT OWNER
                </span>
            <span class="badge badge-warning"  v-else >
                  DEPENDENT
            </span>
          </template>
          <template v-slot:cell(options)="data">

            <b-dropdown text="options">
              <b-dropdown-item href="#"  @click="openEditInstitutionModal(data.item)">Edit</b-dropdown-item>
              <b-dropdown-item href="#" v-if="data.item.status == 'ACTIVE'" @click="openUpdateCustomerStatus('SUSPENDED', data.item)"  >Deactivate</b-dropdown-item>
              <b-dropdown-item href="#" v-else @click="openUpdateCustomerStatus('ACTIVE', data.item)"  >Activate</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="pagination_institution.current_page > 1" class="page-item"><a @click.prevent="changePageInstitution(pagination_institution.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumberInstitution"
                    v-bind:class="[ page == isActivedInstitution ? 'active' : '']" class="page-item"><a @click.prevent="changePageInstitution(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="pagination_institution.current_page < pagination_institution.last_page"  class="page-item"><a  @click.prevent="changePageInstitution(pagination_institution.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </b-tab>
      <b-tab title="Referrals">

        <div class="row">
          <div class="col-md-3">
            <br/>
            <div class="input-group mb-3 pull-right">

              <input type="text" class="form-control" placeholder="Search by phone number" aria-label="" aria-describedby="basic-addon1">

              <div class="input-group-append">
                <button class="btn btn-primary" type="button">Search</button>
              </div>
            </div>
          </div>
          <div class="col-md-9">

          </div>
        </div>

        <br/>

        <b-table striped hover :items="referrals" :fields="referrals_columns">
          <template v-slot:cell(referredByCustomer)="data">
            <a v-if="data.item.referredByCustomer" :href="'/apps/customer/'+data.item.referredByCustomer.id" target="_blank">{{ data.item.referredByCustomer.name }}</a>
          </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="pagination_referrals.current_page > 1" class="page-item"><a @click.prevent="changePageReferrals(pagination_referrals.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumberReferrals"
                    v-bind:class="[ page == isActivedReferrals ? 'active' : '']" class="page-item"><a @click.prevent="changePageReferrals(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="pagination_referrals.current_page < pagination_referrals.last_page"  class="page-item"><a  @click.prevent="changePageReferrals(pagination_referrals.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>

      </b-tab>
      <b-tab title="Customer feedback">
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <b-card class="card-icon text-center mb-30">
              <i class="i-Feedburner"></i>
              <p class="text-muted mt-2 mb-2">Average rating</p>
              <p class="text-primary text-24 line-height-1 m-0">{{ ratingsStats.cumulativeRating.toFixed(2) }}</p>
            </b-card>
          </b-col>
        </b-row>

        <b-table striped hover :items="ratings" :fields="rating_columns">

          <template v-slot:cell(customer)="data">
            <a style="text-decoration: underline; font-weight: bolder;" :href="'/customer/'+data.value.id">{{ data.value.name }}</a>
          </template>


        </b-table>



      </b-tab>

<!--      <b-tab title="Duplicates">

        <div class="row">
          <div class="col-md-3">
            <br/>
            <div class="input-group mb-3 pull-right">

              <input type="text" v-model="phoneNumberDuplicate" class="form-control" placeholder="Search by phone number" aria-label="" aria-describedby="basic-addon1">

              <div class="input-group-append">
                <button @click="searchDuplicate" class="btn btn-primary" type="button">Search</button>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <b-button class="btn btn-danger float-right" v-b-modal.add-duplicate>Add duplicate</b-button>
          </div>
        </div>

        <br/>

        <b-table striped hover :items="duplicates" :fields="duplicate_columns">
          <template v-slot:cell(options)="data">

            <b-dropdown text="options">
              <b-dropdown-item href="#"  @click="openDeleteDuplicate(data.item)">Delete</b-dropdown-item>
              <b-dropdown-item href="#"  @click="openMergeDuplicateModal(data.item)">Merge</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

      </b-tab>-->
    </b-tabs>

    <b-modal id="add-duplicate" title="Add duplicate" @cancel="true" @ok="saveDuplicateData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group " >
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="duplicate.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input required placeholder="712345678" class="form-control" type="text"  v-model="duplicate.phoneNumber" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Cash Balance:</label> <br/>
                <div >
                  <input required  class="form-control" type="text"  v-model="duplicate.cashBalance" />
                </div>
              </div>


              <div class="form-group ">
                <label class="control-label "><strong>Duplicate Type<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                <div >
                  <label for="duplicate_type_name">
                    <input id="duplicate_type_name" type="radio" v-model="duplicate.duplicateType" value="Name" /> Name
                  </label>
                  <label for="duplicate_type_phone_number">
                    <input id="duplicate_type_phone_number" type="radio" v-model="duplicate.duplicateType" value="Phone number" /> Phone number
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="merge-duplicate" title="Merge duplicate" @cancel="true" @ok="mergeDuplicateData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Customer Phone number:</label> <br/>
                <div >
                  <input required class="form-control" type="text"  v-model="duplicate.phoneNumber" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="add-customer" title="Register customer" @cancel="true" @ok="validateBeforeSubmitNewUser"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group " v-if="phone_verified">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="customer.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>

                <div class="input-group mb-2" >
                  <div class="input-group-prepend" style="background-color: #fff;margin-right: 10px;">
                    <div class="input-group-text" style="background-color: #fff;">
                      <img src="@/assets/images/kenyan_flag.png" style="height: 20px; width:35px;"> &nbsp; {{ pref }}
                    </div>
                  </div>

                  <input required placeholder="712345678" :readonly="phone_verified" class="form-control" type="text"  v-model="customer.mobileNumber" />
                </div>
                <div >

                </div>
              </div>
              <div class="form-group " v-if="phone_verified">
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="customer.email" />
                </div>
              </div>

              <div class="form-group " v-if="phone_verified">
                <label class="control-label ">Data of birth:</label> <br/>
                <div >
                  <date-picker name="date_of_birth" valueType="format"  style="width: 100%;" v-model="customer.dob" ></date-picker>
                </div>
              </div>
              <div class="form-group " v-if="phone_verified">
                <label class="control-label ">Gender:</label> <br/>
                <div >
                  <select class="form-control" v-model="customer.gender">
                    <option :value="gender" v-for="gender in genders"> {{ gender }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="edit-customer" title="Update customer details" hide-footer  centered>
      <form action="#" class="form-horizontal"  method="post" v-on:submit.prevent="validateBeforeUpdateCustomerDetails">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group " >
                <label :class="{ 'text-danger': $v.customer.name.$error }">Name:</label> <br/>
                <div >
                  <input class="form-control" v-model.trim="$v.customer.name.$model" type="text"  />
                  <div class="text-danger" v-if="!$v.customer.name.required && $v.customer.name.$dirty">Customer name is required</div>
                </div>
              </div>
              <div class="form-group ">
                <label :class="{ 'text-danger': $v.customer.mobileNumber.$error }">Phone number:</label> <br/>

                <div  >

                  <input required v-model.trim="$v.customer.mobileNumber.$model" class="form-control" type="text"  />
                  <div class="text-danger" v-if="!$v.customer.mobileNumber.required && $v.customer.mobileNumber.$dirty">Customer phone number is required</div>
                </div>
                <div >

                </div>
              </div>

              <div class="form-group " >
                <label class="control-label ">National ID:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="customer.nationalID" />
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="customer.email" />
                </div>
              </div>

              <div class="form-group " >
                <label :class="{ 'text-danger': $v.customer.dob.$error }" >Data of birth:</label> <br/>
                <div >
                  <date-picker v-model.trim="$v.customer.dob.$model" name="date_of_birth" valueType="format"  style="width: 100%;"  ></date-picker>
                  <div class="text-danger" v-if="!$v.customer.dob.required && $v.customer.dob.$dirty">Customer DOB is required</div>
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">Gender:</label> <br/>
                <div >
                  <select class="form-control" v-model="customer.gender">
                    <option :value="gender" v-for="gender in genders"> {{ gender }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group " >
                <button type="submit" class="btn btn-success btn-block"> Save</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="add-verification-code" title="Verification code" @cancel="true" @ok="verifyCode"   centered>
        <form action="#" autocomplete="off" class="form-horizontal"  method="post">

          <div class="text-center">
            <h1 class="mb-3 text-18 " >Please enter verification code </h1>
            <br/>
            <div class="d-flex justify-content-center">
              <CodeInput  :fields="4" :loading="false" class="input " v-on:change="onChange" v-on:complete="onComplete" />
            </div>
          </div>

      </form>
    </b-modal>

    <b-modal id="add-institution" title="Register institution" @cancel="true" @ok="validateBeforeSubmitNewInstitution"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">

              <div class="form-group ">
                <label class="control-label ">Name of Institution:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.nameOfInstitution" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Contact Person Name:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.contactPersonName" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Contact Person Phone number:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.contactPersonMobileNumber" />
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">Email:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.emailAddress" />
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">Location:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.location" />
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">Company Registration:</label>
                <div >
                  <input class="form-control" type="file" ref="companyRegistrationFile" @change="handleFileSelectedCompanyRegistrationFile"   />
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">KRA Pin:</label>
                <div >
                  <input class="form-control" type="file"  ref="taxFile" @change="handleFileSelectedTaxFile"  />
                </div>
              </div>

              <div class="form-group " >
                <label class="control-label ">Director IDs:</label>
                <div >
                  <input class="form-control" type="file" ref="directorsIdFile"  @change="handleFileSelectedDirectorsIdFile" />
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">Board Resolution:</label>
                <div >
                  <input class="form-control" type="file" ref="boardResolutionFile"  @change="handleFileSelectedBoardResolutionFile"  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="edit-institution" title="Edit institution" @cancel="true" @ok="updateInstitution"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">

              <div class="form-group ">
                <label class="control-label ">Name of Institution:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.nameOfInstitution" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Contact Person Name:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.contactPersonName" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Contact Person Phone number:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.contactPersonMobileNumber" />
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">Email:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.emailAddress" />
                </div>
              </div>
              <div class="form-group " >
                <label class="control-label ">Location:</label>
                <div >
                  <input class="form-control" type="text"  v-model="institution.location" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    </b-modal>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import CodeInput from "vue-verification-code-input"

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import { required,requiredIf} from 'vuelidate/lib/validators'


export default {
  data() {
    return {
      phoneNumberDuplicate:"",
      phoneNumber:"",
      offset: 4,
      offset_institution: 4,
      offset_referrals: 4,
      pref:"254",
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:["loyaltyId","name","gender","dob","created", "status","customerType","customerTier","options"],
      institution_columns:["created","nameOfInstitution",{
        key: 'name',
        label: 'Contact Person Name',
        sortable: false
      },{
        key: 'mobileNumber',
        label: 'Contact Person Mobile',
        sortable: false
      },{
        key: 'certificateOfRegistration',
        label: 'CR12',
        sortable: false
      },
        {
          key: 'taxCompliance',
          label: 'Tax Pin',
          sortable: false
        },"directorsId","boardResolution","location", "status","options"],
      customers:[],
      institutions:[],
      ratings:[],
      rating_columns:["customer","rating","comments"],
      duplicate_columns:["name","phoneNumber","cashBalance","duplicateType","options"],
      referrals_columns:["created","name","mobileNumber","referredByCustomer"],
      referrals:[],
      genders:["Male","Female"],
      ratingsStats:{
        cumulativeRating:0,
      },
      customer:{
        name:"",
        mobileNumber:"",
        email:"",
        password:"N/A",
        dob:"",
        gender:"",
        id:0
      },
      institution:{
        emailAddress:"",
        contactPersonName:"",
        contactPersonMobileNumber:"",
        nameOfInstitution:"",
        certificateOfRegistration:"",
        taxCompliance:"",
        location:"",
        directorsId:"",
        boardResolution:"",
        id:0
      },
      phone_verified:false,
      verificationCode:"",
      customerVerificationCode:"",
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      pagination_institution: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      pagination_referrals: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      status:"",
      duplicates:[],
      duplicate:{
        id:0,
        name:"",
        phoneNumber:"",
        duplicateType:"Phone number",
        cashBalance:""
      },
      taxFile:"",
      companyRegistrationFile:"",
      boardResolutionFile:"",
      directorsIdFile:"",
      uploadCount: 0
    };
  },
  components: {
    Loading,
    DatePicker,
    CodeInput
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
    isActivedReferrals: function () {
      return this.pagination_referrals.current_page + 1;
    },
    pagesNumberReferrals: function () {
      if (!this.pagination_referrals.to) {
        return [];
      }
      var from = this.pagination_referrals.current_page - this.offset_referrals;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination_referrals.last_page) {
        to = this.pagination_referrals.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
    isActivedInstitution: function () {
      return this.pagination_institution.current_page + 1;
    },
    pagesNumberInstitution: function () {
      if (!this.pagination_institution.to) {
        return [];
      }
      var from = this.pagination_institution.current_page - this.offset_institution;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset_institution * 2);
      if (to >= this.pagination_institution.last_page) {
        to = this.pagination_institution.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  validations: {
    customer:{
      name:{required},
      mobileNumber:{required},
      dob:{required},
    }
  },
  mounted (){
    this.getCustomersData("individuals");
    this.getCustomersData("institutions");
    this.getCustomerRatingsData();
    this.getDuplicateData();
    this.getReferralsData();
  },
  methods: {
    ...mapActions(["getReferrals","editInstitution","uploadFile","saveInstitution","getCustomers","getCustomerRatings","sendVerificationCode","registerCustomer","searchCustomerByPhoneNumber","updateCustomerDetails","updateCustomerStatus","getDuplicates","saveDuplicate","deleteDuplicate","mergeDuplicate"]),
    openEditInstitutionModal(institution){

      this.institution = {
        emailAddress:institution.email,
        contactPersonName:institution.name,
        contactPersonMobileNumber:institution.mobileNumber,
        nameOfInstitution:institution.nameOfInstitution,
        location:institution.location,
        id:institution.id
      };
      this.$bvModal.show('edit-institution');
    },
    handleFileSelectedTaxFile(){
      this.taxFile = this.$refs.taxFile.files[0];
    },
    handleFileSelectedCompanyRegistrationFile(){
      this.companyRegistrationFile = this.$refs.companyRegistrationFile.files[0];
    },
    handleFileSelectedBoardResolutionFile(){
      this.boardResolutionFile = this.$refs.boardResolutionFile.files[0];
    },
    handleFileSelectedDirectorsIdFile(){
      this.directorsIdFile = this.$refs.directorsIdFile.files[0];
    },
    initiateFileUpload() {
      if(this.taxFile) {
        this.uploadInstitutionFiles(this.taxFile,"tax");
      } else {
        this.uploadCount = this.uploadCount + 1;
      }

      if(this.companyRegistrationFile) {
        this.uploadInstitutionFiles(this.companyRegistrationFile, "cr12");
      } else {
        this.uploadCount = this.uploadCount + 1;
      }

      if(this.boardResolutionFile) {
        this.uploadInstitutionFiles(this.boardResolutionFile, "board");
      } else {
        this.uploadCount = this.uploadCount + 1;
      }

      if(this.directorsIdFile) {
        this.uploadInstitutionFiles(this.directorsIdFile, "directors");
      } else {
        this.uploadCount = this.uploadCount + 1;
      }

      if(this.uploadCount == 4) {
        this.enrollInstitution();
      }

    },
    uploadInstitutionFiles(institutionFile, filetype) {
      let self = this;
      let formData = new FormData();
      formData.append('file', institutionFile);

      self.isLoading = true;

      this.uploadFile( formData)
          .then(function (response) {

            self.isLoading = false;

            if(response.data.statusCode == 200){
              if(filetype == "tax") {
                self.institution.taxCompliance = response.data.message;
              } else if(filetype == "cr12"){
                self.institution.certificateOfRegistration = response.data.message;
              } else if(filetype == "board"){
                self.institution.boardResolution = response.data.message;
              } else if(filetype == "directors"){
                self.institution.directorsId = response.data.message;
              }

              self.uploadCount = self.uploadCount + 1;

              if(self.uploadCount == 4) {
                  self.enrollInstitution();
              }

            } else {
              self.$bvToast.toast(response.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });
            }

          })
          .catch(function (error) {
            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    validateBeforeSubmitNewInstitution() {
      this.initiateFileUpload();
     /* if(this.taxFile) {
        this.uploadInstitutionFiles(this.taxFile, "kra")
      } else if(this.companyRegistrationFile){
        this.uploadInstitutionFiles(this.taxFile, "company registration")
      } else {
        this.enrollInstitution();
      }*/
    },
    openEditCustomerModal(customer){

      this.customer = customer;

      this.$bvModal.show("edit-customer")

    },
    openMergeDuplicateModal(duplicate){

      this.duplicate = duplicate;

      this.$bvModal.show("merge-duplicate")

    },

    openDeleteDuplicate(duplicate){

      this.duplicate = duplicate;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.duplicate.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteDuplicate();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    confirmDeleteDuplicate(){
      this.isLoading = true;

      let self = this;

      this.deleteDuplicate(this.duplicate).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Duplicate deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getDuplicateData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    openUpdateCustomerStatus(status, customer){

      this.status = status;
      this.customer = customer;

      let self = this;
      this.$bvModal.msgBoxConfirm('Confirm customer status update ?')
        .then(value => {

          if(value){
            self.updateCustomerStatusData();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },


    updateCustomerStatusData(){
      let self = this;

      this.isLoading = true;

      this.updateCustomerStatus({status: this.status, customerId: this.customer.id })
        .then(function () {
          self.isLoading = false;

          self.$bvToast.toast("Customer details updated successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getCustomersData("individuals");
          self.getCustomersData("institutions");
        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

        })
    },

    onChange(v) {

    },

    changePage: function (page) {
      this.pagination.current_page = page;
      this.getCustomersData("individuals");
    },
    changePageInstitution: function (page) {
      this.pagination_institution.current_page = page;
      this.getCustomersData("institutions");
    },
    changePageReferrals: function (page) {
      this.pagination_referrals.current_page = page;
      this.getCustomersData("institutions");
    },
    onComplete(v) {
      this.customerVerificationCode = v;
    },

    getDuplicateData(){
      let self = this;
      this.getDuplicates()
        .then(function (duplicates) {
          console.log(duplicates);
          self.duplicates = duplicates;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getReferralsData(){
      let self = this;
      this.getReferrals({page: this.pagination_referrals.current_page})
          .then(function (referrals) {
            self.referrals = referrals.content;
            self.pagination_referrals.current_page = referrals.number;
            self.pagination_referrals.total = referrals.totalElements;
            self.pagination_referrals.per_page = referrals.numberOfElements;
            self.pagination_referrals.from = referrals.pageable.offset + 1 ;
            self.pagination_referrals.to = referrals.pageable.pageSize;
            self.pagination_referrals.last_page = referrals.totalPages ;

          })
          .catch(function (error) {


            if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/login");
              }

            }
          })
    },

    getCustomersData(type){
      let self = this;
      this.getCustomers({page: this.pagination.current_page, customerType: type})
        .then(function (customers) {
          if(type == "individuals") {
            self.customers = customers.content;
            self.pagination.current_page = customers.number;
            self.pagination.total = customers.totalElements;
            self.pagination.per_page = customers.numberOfElements;
            self.pagination.from = customers.pageable.offset + 1 ;
            self.pagination.to = customers.pageable.pageSize;
            self.pagination.last_page = customers.totalPages ;
          } else {
            self.institutions = customers.content;
            self.pagination_institution.current_page = customers.number;
            self.pagination_institution.total = customers.totalElements;
            self.pagination_institution.per_page = customers.numberOfElements;
            self.pagination_institution.from = customers.pageable.offset + 1 ;
            self.pagination_institution.to = customers.pageable.pageSize;
            self.pagination_institution.last_page = customers.totalPages ;
          }

        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getCustomerRatingsData(){
      let self = this;
      this.getCustomerRatings()
        .then(function (customerRating) {
          self.ratingsStats.cumulativeRating = customerRating.averageRating
          self.ratings = customerRating.ratingsList;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    validateBeforeSubmitNewUser(){
         if(!this.phone_verified){
             this.sendVerificationCodeToCustomer();
         }
         else{
            this.enrollCustomer();
         }
    },

    validateBeforeUpdateCustomerDetails(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateCustomerDetailsData();
      }
    },

    updateCustomerDetailsData(){
      let self = this;

      this.isLoading = true;

      this.$bvModal.hide("edit-customer");

      this.updateCustomerDetails(this.customer)
              .then(function () {

                self.isLoading = false;

                self.$bvToast.toast("Customer details updated successfully.", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getCustomersData("individuals");


              })
              .catch(function (error) {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
              })
    },

    mergeDuplicateData(){
      let self = this;

      this.isLoading = true;

      let mergeDuplicate = {
        phoneNumber: this.duplicate.phoneNumber,
        duplicateId: this.duplicate.id
      };


      this.mergeDuplicate(mergeDuplicate)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Duplicate data merged successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getDuplicateData();

        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },

    saveDuplicateData(){
      let self = this;

      this.isLoading = true;

      this.saveDuplicate(this.duplicate)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Duplicate saved successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getDuplicateData();


        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },
    updateInstitution(){
      let self = this;

      this.isLoading = true;

      this.editInstitution(this.institution)
          .then(function () {

            self.isLoading = false;

            self.$bvToast.toast("Institution updated successfully.", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            self.getCustomersData("institutions");

          })
          .catch(function (error) {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    },
    enrollInstitution(){
      let self = this;

      this.isLoading = true;

      this.saveInstitution(this.institution)
          .then(function () {

            self.isLoading = false;

            self.$bvToast.toast("Institution registered successfully.", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            self.getCustomersData("institutions");


          })
          .catch(function (error) {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    },

    enrollCustomer(){
      let self = this;

      this.isLoading = true;

      this.customer.mobile_number = this.pref+this.customer.mobile_number;

      this.registerCustomer(this.customer)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Customer registered successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getCustomersData("individuals");


        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },
    searchCustomer(){
        let self = this;

      self.isLoading = true;

      this.searchCustomerByPhoneNumber({phone_number: this.phoneNumber})
        .then(function (customers) {

          self.isLoading = false;

          self.customers = customers;
        })
        .catch(function (error) {

          self.isLoading = false;

        })


    },
    searchDuplicate(){
      let self = this;

      self.isLoading = true;

      this.searchCustomerByPhoneNumber({phone_number: this.phoneNumber})
        .then(function (customers) {

          self.isLoading = false;

          self.customers = customers;
        })
        .catch(function (error) {

          self.isLoading = false;

        })


    },
    sendVerificationCodeToCustomer(){

      let self = this;

      this.isLoading = true;

      this.sendVerificationCode({phoneNumber: this.pref+this.customer.mobileNumber})
        .then(function (verificationCode) {
          self.isLoading = false;

          self.$bvModal.show("add-verification-code");

          self.verificationCode = verificationCode;
        })
        .catch(function (error) {

          self.isLoading = false;

        })
    },
    verifyCode(){
      if(parseInt(this.customerVerificationCode) == parseInt(this.verificationCode)){
        this.phone_verified = true;
        this.$bvModal.show("add-customer");
      }
      else{
        this.$bvToast.toast("Verification does not match", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });
      }
    }
  }
};
</script>
